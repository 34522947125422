var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("cs-flex", { staticClass: "box" }, [
    _c("div", { staticClass: "image" }, [_vm._t("image")], 2),
    _c("div", { staticClass: "text-container" }, [
      _c("h3", [_vm._v(_vm._s(_vm.title))]),
      _c("p", [_vm._v(_vm._s(_vm.text))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }