var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "about" } },
    [
      _c("h2", { staticClass: "collaboration-title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "Steelspace has an innovative vision to offer a unique solution for the needs of structural engineers, software developers, and solution providers."
              )
            ) +
            " "
        ),
      ]),
      _c(
        "cs-flex",
        { staticClass: "content-boxes", attrs: { columnGap: "2rem" } },
        _vm._l(_vm.boxes, function (box, index) {
          return _c("landing-content-box", {
            key: index,
            class: `animation-${index + 1}`,
            attrs: { title: box.title, text: box.text },
            scopedSlots: _vm._u(
              [
                {
                  key: "image",
                  fn: function () {
                    return [_c(box.image, { tag: "component" })]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c(
        "cs-flex",
        { staticClass: "idea-behind-steelspace", attrs: { align: "stretch" } },
        [
          _c(
            "cs-flex",
            { staticClass: "container-left", attrs: { "fill-width": "" } },
            [
              _c("cs-flex", { attrs: { vertical: "" } }, [
                _c("h2", [
                  _vm._v(_vm._s(_vm.$t("The idea behind Steelspace"))),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "Cloud-based developments are just not yet exploited in the construction industry, which we would like to change with you and Steelspace."
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "cs-flex",
            { staticClass: "container-right", attrs: { "fill-width": "" } },
            [
              _c("p", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "Our team always strive to implement the most up-to-date solutions. Our motivation when we started to plan Steelspace was to create a practical, genuinely valuable tool in your hands - which has started now. As the structural engineer of today (and not yesterday or tomorrow) we wanted to level up your work in terms of"
                      )
                    ) +
                    " "
                ),
                _c("br"),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.$t("- knowledge sharing")) + " "),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("- convenient display of your model and its data")
                    ) +
                    " "
                ),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("- option to share and view designs")) +
                    " "
                ),
                _c("br"),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "outside any specific software. This is brought to life by Steelspace."
                      )
                    ) +
                    " "
                ),
                _c("br"),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "A space for connecting demands and solutions, users and developers, models and calculations in a way never seen before."
                      )
                    )
                ),
                _c("br"),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "Be the part of the evolution of this environment which changes the game of structural design."
                      )
                    ) +
                    " "
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "packages", attrs: { id: "partnership" } },
        [
          _c("h2", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "Steelspace is continuously evolving. Are you interested in providing your services or calculation package in Steelspace?"
                  )
                ) +
                " "
            ),
          ]),
          _vm._m(0),
          _c("p", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "All additional services in Steelspace are co-developed with trusted structural analysis software houses or academic research institutes."
                  )
                ) +
                " "
            ),
          ]),
          _c("p", [
            _c("b", [
              _vm._v(
                _vm._s(_vm.$t("Contact us and let’s discuss how you can join!"))
              ),
            ]),
          ]),
          _c(
            "cs-btn",
            {
              staticClass: "steelspace-btn",
              attrs: {
                href: "https://consteelsoftware.com/contact/#collaborate",
                target: "_blank",
              },
            },
            [_vm._v(_vm._s(_vm.$t("Contact us")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "embed-container" }, [
      _c("iframe", {
        attrs: {
          src: "https://www.youtube.com/embed/q2jRqbhn7-Q",
          frameborder: "0",
          allow:
            "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }