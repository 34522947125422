var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "landing-form login" }, [
    _c("h2", [_vm._v(_vm._s(_vm.$t("Login")))]),
    _c("p", [
      _vm._v(
        _vm._s(
          _vm.$t("You can use your Consteel account to log in to Steelspace.")
        )
      ),
    ]),
    _c(
      "div",
      [
        _c(
          "cs-btn",
          {
            attrs: { dark: "", color: "#006CD4" },
            on: { click: _vm.openAuthWindow },
          },
          [_vm._v(" " + _vm._s(_vm.$t("Sign in with Consteel")) + " ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "cs-btn",
          {
            staticClass: "mt-3",
            attrs: { dark: "", color: "#424b5a" },
            on: { click: _vm.openMicrosoftAuthWindow },
          },
          [
            _c("v-icon", { staticClass: "mr-2" }, [_vm._v(" mdi-microsoft ")]),
            _vm._v(" " + _vm._s(_vm.$t("Sign in with Microsoft")) + " "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }