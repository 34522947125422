
import { CsFlex } from '@consteel/csuetify'
import Vue from 'vue'

export default Vue.extend({
  name: 'LandingContentBox',
  components: {
    CsFlex,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
})
