// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/blueback.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".landing[data-v-a8baa566] {\n  background: #F9F9F9;\n  color: rgba(0, 0, 0, 0.87);\n}\n.top-menu[data-v-a8baa566] {\n  position: fixed;\n  top: 0;\n  transition: background 0.25s;\n}\n.landing-right[data-v-a8baa566] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  padding-bottom: 20%;\n  flex-basis: 65%;\n}\n.landing-right svg[data-v-a8baa566] {\n  width: 45%;\n  height: auto;\n  margin-left: 5%;\n}\n.landing-right h2[data-v-a8baa566] {\n  font-size: 1.7rem;\n  margin: 6rem clamp(6rem, 10vw, 20rem) 0;\n  color: white;\n}\n.landing-left[data-v-a8baa566] {\n  padding-left: 7rem;\n}\n@media screen and (max-width: 960px) {\n.landing-top[data-v-a8baa566] {\n    flex-direction: column-reverse;\n}\n.landing-top .landing-right[data-v-a8baa566] {\n    background-position-x: 75%;\n}\n.landing-top .landing-right[data-v-a8baa566], .landing-top .landing-left .landing-form[data-v-a8baa566] {\n    text-align: center;\n}\n.landing-top .landing-left[data-v-a8baa566] {\n    padding: 0 4rem;\n}\n}\n.theme--light.landing .top-menu[data-v-a8baa566]:not([data-scroll=\"0\"]) {\n  background: #1c1e2280;\n}\n.theme--dark.landing .top-menu[data-v-a8baa566]:not([data-scroll=\"0\"]) {\n  background: #1c1e2280;\n}", ""]);
// Exports
module.exports = exports;
