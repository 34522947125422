var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "136",
        height: "92",
        viewBox: "0 0 136 92",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M80.7432 18.9628V13.6021H78.5227C74.1849 9.11682 64.6137 6 53.4999 6C41.8895 6 31.9641 9.4018 27.9275 14.2157H26.1025V18.7516C26.1025 18.7516 26.1025 18.9461 26.1025 19C26.1025 19.0539 26.1025 19.1172 26.1025 19.1172C26.3173 26.2083 38.4416 32 53.4943 32C68.6838 32 80.7432 26.1412 80.7432 18.9628Z",
          fill: "#006CD4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M53.4172 28.0522C68.4999 28.0522 80.7269 21.7725 80.7269 14.0261C80.7269 6.2797 68.4999 0 53.4172 0C38.3344 0 26.1074 6.2797 26.1074 14.0261C26.1074 21.7725 38.3344 28.0522 53.4172 28.0522Z",
          fill: "#1EB4FA",
        },
      }),
      _c("path", {
        attrs: {
          d: "M71.8262 25.063L74.4746 23.7333L85.481 29.5592L83.2482 31.0968L71.8262 25.063Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M74.466 24.5117L84.1455 29.6367L83.2008 30.2878L73.3385 25.0796L74.466 24.5255V24.5117ZM74.4826 22.9686L70.3271 25.0602L83.3088 31.9167L86.8299 29.4982L74.4826 22.9575V22.9686Z",
          fill: "#1EB4FA",
        },
      }),
      _c("path", {
        attrs: {
          d: "M81.5854 33.5069L89.6942 28.2821L128.786 50.9405L120.359 55.8966L81.5854 33.5069Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M89.7137 29.0938L127.412 50.9377L120.359 55.0932L82.9126 33.4847L89.7137 29.1049V29.0938ZM89.6749 27.4815L80.2559 33.5512L120.359 56.6972L130.16 50.9377L89.6749 27.4731V27.4815Z",
          fill: "#1EB4FA",
        },
      }),
      _c("path", {
        attrs: {
          d: "M53.417 25.6448C66.8979 25.6448 77.8263 20.2308 77.8263 13.5524C77.8263 6.87392 66.8979 1.45996 53.417 1.45996C39.9362 1.45996 29.0078 6.87392 29.0078 13.5524C29.0078 20.2308 39.9362 25.6448 53.417 25.6448Z",
          fill: "#006CD4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M70.3135 25.0464V29.2538L80.2561 35V33.5625L83.0209 31.7589L70.3135 25.0464Z",
          fill: "#006CD4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M80.2559 33.5508V38.5L120.362 61.5V56.6973L80.2559 33.5508Z",
          fill: "#006CD4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M120.362 61.5L130.16 55.8815V50.9349L120.362 56.6972V61.5Z",
          fill: "#006CD4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M53.3038 25.6448C66.0579 25.6448 76.3971 21.4054 76.3971 16.1758C76.3971 10.9463 66.0579 6.70691 53.3038 6.70691C40.5497 6.70691 30.2104 10.9463 30.2104 16.1758C30.2104 21.4054 40.5497 25.6448 53.3038 25.6448Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.644 56.7443L61.4035 56.193L61.0905 85.14L123.929 77.5493",
          stroke: "#006CD4",
          "stroke-width": "4",
          "stroke-miterlimit": "10",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12.0702 63.1659C18.1841 63.1659 23.1403 60.2909 23.1403 56.7443C23.1403 53.1978 18.1841 50.3228 12.0702 50.3228C5.95628 50.3228 1 53.1978 1 56.7443C1 60.2909 5.95628 63.1659 12.0702 63.1659Z",
          fill: "#006CD4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12.0177 60.717C17.8853 60.717 22.6419 58.3901 22.6419 55.5199C22.6419 52.6496 17.8853 50.3228 12.0177 50.3228C6.15015 50.3228 1.39355 52.6496 1.39355 55.5199C1.39355 58.3901 6.15015 60.717 12.0177 60.717Z",
          fill: "white",
          stroke: "#1EB4FA",
          "stroke-miterlimit": "10",
        },
      }),
      _c("path", {
        attrs: {
          d: "M60.6971 63.1659C66.811 63.1659 71.7673 60.2909 71.7673 56.7443C71.7673 53.1978 66.811 50.3228 60.6971 50.3228C54.5832 50.3228 49.627 53.1978 49.627 56.7443C49.627 60.2909 54.5832 63.1659 60.6971 63.1659Z",
          fill: "#006CD4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M60.6476 60.717C66.5151 60.717 71.2717 58.3901 71.2717 55.5199C71.2717 52.6496 66.5151 50.3228 60.6476 50.3228C54.78 50.3228 50.0234 52.6496 50.0234 55.5199C50.0234 58.3901 54.78 60.717 60.6476 60.717Z",
          fill: "white",
          stroke: "#1EB4FA",
          "stroke-miterlimit": "10",
        },
      }),
      _c("path", {
        attrs: {
          d: "M61.0912 91.9024C67.2051 91.9024 72.1613 89.0273 72.1613 85.4808C72.1613 81.9342 67.2051 79.0592 61.0912 79.0592C54.9773 79.0592 50.021 81.9342 50.021 85.4808C50.021 89.0273 54.9773 91.9024 61.0912 91.9024Z",
          fill: "#006CD4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M61.0411 89.4506C66.9087 89.4506 71.6653 87.1238 71.6653 84.2535C71.6653 81.3832 66.9087 79.0564 61.0411 79.0564C55.1736 79.0564 50.417 81.3832 50.417 84.2535C50.417 87.1238 55.1736 89.4506 61.0411 89.4506Z",
          fill: "white",
          stroke: "#1EB4FA",
          "stroke-miterlimit": "10",
        },
      }),
      _c("path", {
        attrs: {
          d: "M123.93 83.561C130.044 83.561 135 80.6859 135 77.1394C135 73.5928 130.044 70.7178 123.93 70.7178C117.816 70.7178 112.86 73.5928 112.86 77.1394C112.86 80.6859 117.816 83.561 123.93 83.561Z",
          fill: "#006CD4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M123.88 81.1092C129.748 81.1092 134.504 78.7824 134.504 75.9121C134.504 73.0418 129.748 70.715 123.88 70.715C118.012 70.715 113.256 73.0418 113.256 75.9121C113.256 78.7824 118.012 81.1092 123.88 81.1092Z",
          fill: "white",
          stroke: "#1EB4FA",
          "stroke-miterlimit": "10",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }