
import CollaborationSvg from '@/assets/landing/LandingContentBox/collaboration.svg.vue'
import CompatSvg from '@/assets/landing/LandingContentBox/compat.svg.vue'
import SharingSvg from '@/assets/landing/LandingContentBox/sharing.svg.vue'
import WorkflowSvg from '@/assets/landing/LandingContentBox/workflow.svg.vue'
import LandingContentBox from '@/components/Landing/LandingContentBox.vue'
import { CsBtn, CsFlex, CsGrid } from '@consteel/csuetify/src/components'
import Vue from 'vue'

export default Vue.extend({
  name: 'AboutContent',
  components: {
    LandingContentBox,
    CsFlex,
    CsBtn,
    CsGrid,
    CollaborationSvg,
    CompatSvg,
    SharingSvg,
    WorkflowSvg,
  },
  data() {
    return {
      boxes: [
        {
          image: CollaborationSvg,
          title: this.$t('Cloud-based collaboration'),
          text: this.$t(
            'The aim of Steelspace is to create a cloud-based open platform for structural models, where you can upload, store, and view structural models and their analysis and design results and share them with your colleagues.'
          ),
        },
        {
          image: CompatSvg,
          title: this.$t('Seamless compatibility'),
          text: this.$t(
            'Compatibility with other software is essential, as structural engineers use different software during their workflow. Right now, Steelspace is fully compatible with structural models from Consteel software ... and more to come!'
          ),
        },
        {
          image: WorkflowSvg,
          title: this.$t('Enhanced workflow'),
          text: this.$t(
            'The concept of Steelspace does not stop at viewing and sharing structural models. Performing additional structural analysis and design calculations and other services enhance your workflow and complement existing functionalities and calculations of other software platforms.'
          ),
        },
        {
          image: SharingSvg,
          title: this.$t('Knowledge-sharing environment'),
          text: this.$t(
            'Steelspace is an ideal platform for a classic knowledge-sharing environment for modeling software companies or individual researchers connecting them to engineers. This environment efficiently lets practical users be aware of the existence of their solution and provides a standard mechanical and graphical interface for the calculations and result sharing.'
          ),
        },
      ],
      landingIdeaDetailed: this.$t(
        'Our team always strive to implement the most up-to-date solutions. Our motivation when we started to plan Steelspace was to create a practical, genuinely valuable tool in your hands - which has started now. As the structural engineer of today (and not yesterday or tomorrow) we wanted to level up your work in terms of <br><br> - knowledge sharing <br> - convenient display of your model and its data <br> - option to share and view designs <br><br> outside any specific software. This is brought to life by Steelspace. <br><br> A space for connecting demands and solutions, users and developers, models and calculations in a way never seen before. <br><br> Be the part of the evolution of this environment which changes the game of structural design.'
      ),
    }
  },
})
