var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    { staticClass: "top-menu", attrs: { justify: "flex-end" } },
    [
      _c("a", { attrs: { href: "#about" } }, [_vm._v(_vm._s(_vm.$t("About")))]),
      _c("a", { attrs: { href: "#partnership" } }, [
        _vm._v(_vm._s(_vm.$t("Partnership"))),
      ]),
      _c("a", { on: { click: _vm.openAuthWindow } }, [
        _vm._v(_vm._s(_vm.$t("Login"))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }