var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-theme-provider",
    { attrs: { light: "" } },
    [
      _c(
        "cs-flex",
        { staticClass: "landing", attrs: { vertical: "" } },
        [
          _c("top-menu", { attrs: { "data-scroll": _vm.scroll } }),
          _c(
            "cs-flex",
            { staticClass: "landing-top", attrs: { justify: "space-between" } },
            [
              _c(
                "cs-flex",
                {
                  staticClass: "landing-left",
                  attrs: {
                    vertical: "",
                    justify: "center",
                    align: "flex-start",
                    "no-content-stretch": "",
                  },
                },
                [_c("steelspace-logo-color-svg"), _c("login")],
                1
              ),
              _c(
                "cs-flex",
                {
                  staticClass: "landing-right",
                  attrs: {
                    vertical: "",
                    justify: "center",
                    noContentStretch: "",
                  },
                },
                [
                  _c("screenshot-svg", { staticClass: "mb-8 mt-16" }),
                  _c("h2", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "An open platform for structural engineers to make knowledge sharing and collaboration easier."
                          )
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("about-content"),
          _c("footer-links"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }