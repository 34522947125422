import { render, staticRenderFns } from "./AboutContent.vue?vue&type=template&id=ce643918&scoped=true"
import script from "./AboutContent.vue?vue&type=script&lang=ts"
export * from "./AboutContent.vue?vue&type=script&lang=ts"
import style0 from "./AboutContent.vue?vue&type=style&index=0&id=ce643918&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce643918",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ce643918')) {
      api.createRecord('ce643918', component.options)
    } else {
      api.reload('ce643918', component.options)
    }
    module.hot.accept("./AboutContent.vue?vue&type=template&id=ce643918&scoped=true", function () {
      api.rerender('ce643918', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Landing/AboutContent.vue"
export default component.exports