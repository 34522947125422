
import { openAuthWindow, popUpToMicrosoftAuth } from '@/modules/auth'
import { CsBtn } from '@consteel/csuetify'
import Vue from 'vue'

export default Vue.extend({
  name: 'Login',
  components: {
    CsBtn,
  },
  methods: {
    openAuthWindow: openAuthWindow,
    openMicrosoftAuthWindow: popUpToMicrosoftAuth,
  },
})
