var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    { staticClass: "footer" },
    [
      _c(
        "a",
        {
          on: {
            click: function ($event) {
              $event.stopPropagation()
              _vm.showLegalDialog = true
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("Terms of Service")))]
      ),
      _c(
        "a",
        { attrs: { href: "https://consteelsoftware.com", target: "_blank" } },
        [_vm._v(_vm._s(_vm.$t("Consteel Solutions Ltd.")))]
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.linkedin.com/company/consteel-software/",
            target: "_blank",
          },
        },
        [_vm._v(_vm._s(_vm.$t("LinkedIn")))]
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.facebook.com/ConSteel/",
            target: "_blank",
          },
        },
        [_vm._v(_vm._s(_vm.$t("Facebook")))]
      ),
      _c("a", { attrs: { href: "http://www.kesz.hu/", target: "_blank" } }, [
        _vm._v(_vm._s(_vm.$t("KÉSZ Group"))),
      ]),
      _c("legal-dialog", {
        attrs: { text: "tos" },
        model: {
          value: _vm.showLegalDialog,
          callback: function ($$v) {
            _vm.showLegalDialog = $$v
          },
          expression: "showLegalDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }