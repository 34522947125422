
import ScreenshotSvg from '@/assets/screenshot.svg.vue'
import SteelspaceLogoColorSvg from '@/assets/SteelspaceLogoColor.svg.vue'
import AboutContent from '@/components/Landing/AboutContent.vue'
import FooterLinks from '@/components/Landing/FooterLinks.vue'
import Login from '@/components/Landing/Login.vue'
import TopMenu from '@/components/TopMenu.vue'
import { CsFlex } from '@consteel/csuetify'
import Vue from 'vue'

export default Vue.extend({
  name: 'Landing',
  components: {
    TopMenu,
    AboutContent,
    FooterLinks,
    Login,
    CsFlex,
    ScreenshotSvg,
    SteelspaceLogoColorSvg,
  },
  data() {
    return {
      scroll: 0,
    }
  },
  methods: {
    onscroll() {
      this.scroll = window.scrollY
    },
  },
  mounted() {
    document.addEventListener('scroll', this.onscroll, { passive: true })
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.onscroll)
  },
})
