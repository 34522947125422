var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "135",
        height: "89",
        viewBox: "0 0 135 89",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M134 55.0493L131.043 53.4545C129.613 51.8747 127.935 50.5377 126.077 49.4958C124.939 48.8452 123.757 48.2735 122.541 47.7848C125.641 44.2434 127.15 40.3115 126.965 36.2961V30.8469L125.573 30.677C123.704 26.6289 120.037 22.9265 114.892 19.9456C104.122 13.7273 88.5433 11.5423 74.1363 14.2162C72.6867 12.7937 71.0572 11.5671 69.2893 10.5675C57.6069 3.82158 38.6569 3.82158 27.0461 10.5675C23.5733 12.5856 21.0246 15.0658 19.5639 17.7784L18.1479 17.6085V23.7344H18.1658C18.3238 26.8286 19.8143 29.8155 22.5478 32.4507C23.7891 33.6935 25.193 34.7625 26.7212 35.6284L89.9533 72.1332C94.8719 74.9711 101.317 76.393 107.759 76.393C114.2 76.393 120.63 74.9711 125.516 72.1332L125.832 71.9424L125.859 71.9275L126.196 71.7367C131.147 68.8601 133.902 65.0087 133.991 60.8712L134 55.0493Z",
          fill: "#006CD4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M36.3826 63.1336L36.3886 63.1366V63.1307L36.3915 63.1277H36.3886V60.0543L33.7474 61.6014L31.5087 60.3107L13.152 70.9765L6.68927 67.2474L1.54116 78.5184L0 78.8344V81.8898L25.2606 77.9669L25.2696 74.9084L21.0456 75.5344L18.0348 73.7965L36.3826 63.1336Z",
          fill: "#1EB4FA",
        },
      }),
      _c("path", {
        attrs: {
          d: "M7.03774 65.2471L13.1666 68.7826L31.5233 58.1167L34.9186 60.0752L16.5619 70.744L23.0693 74.5L1.2666 77.8864L7.03774 65.2471Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M31.5268 58.9781L33.4347 60.0781L15.0749 70.744L20.8669 74.0886L2.51914 76.9384L7.38108 66.3023L13.1671 69.644L31.5268 58.9781ZM31.5268 57.2551L30.7786 57.6874L13.1671 67.921L8.12334 65.0116L6.70143 64.1918L6.02177 65.6823L1.16281 76.3184L0.0151367 78.8343L2.74867 78.411L21.0935 75.5612L25.2668 74.9113L21.6122 72.8038L18.035 70.7469L34.168 61.3718L36.3918 60.0781L34.1769 58.7933L32.2691 57.6933L31.5239 57.2611L31.5268 57.2551Z",
          fill: "#1EB4FA",
        },
      }),
      _c("path", {
        attrs: {
          d: "M49.1409 75.0783L33.5296 84.1523L30.7842 82.954V85.7471L35.667 88.5671L54.0237 77.8983L60.4864 81.6304L67.1757 66.985L41.9151 70.9079L49.1409 75.0783Z",
          fill: "#006CD4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M41.9033 68.1385L41.9152 70.9079L46.4195 70.2044L41.9033 68.1385Z",
          fill: "#006CD4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M65.6113 67.2234L67.1763 66.985V64.2186L65.6113 67.2234Z",
          fill: "#006CD4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M32.272 82.9868L50.6287 72.321L44.1213 68.562L65.924 65.1786L60.1499 77.8178L54.024 74.2795L35.6673 84.9483L32.272 82.9868Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M64.6721 66.1265L59.8131 76.7626L54.0241 73.418L35.6644 84.0868L33.7596 82.9868L52.1163 72.318L46.3243 68.9733L64.6721 66.1265ZM67.1761 64.2277L64.4426 64.6539L46.0977 67.5007L41.9244 68.1506L45.585 70.2671L49.1622 72.321L33.0173 81.6961L30.7905 82.9898L33.0203 84.2776L34.9251 85.3776L35.6733 85.8098L36.4216 85.3746L54.0301 75.1409L59.0739 78.0533L60.4928 78.8731L61.1754 77.3826L66.0344 66.7466L67.1821 64.2277H67.1761Z",
          fill: "#006CD4",
        },
      }),
      _c("path", {
        attrs: {
          d: "M114.52 15.3548C103.49 8.99344 87.6731 7.14226 73.8832 9.78936C72.4155 8.29102 70.7436 7.00746 68.9169 5.97671C57.4223 -0.658903 38.845 -0.658903 27.422 5.97671C17.7488 11.5988 16.3239 20.175 23.0787 26.7003C24.2764 27.8967 25.6312 28.9246 27.106 29.7558L90.3261 66.2457C99.9725 71.8142 115.56 71.8142 125.144 66.2457L125.478 66.043C125.594 65.9804 125.704 65.9178 125.82 65.8493C135.777 60.0632 135.726 50.6851 125.704 44.8991C124.282 44.0927 122.793 43.4095 121.254 42.8571C129.675 34.0931 127.424 22.8072 114.52 15.3548Z",
          fill: "white",
        },
      }),
      _c("path", {
        attrs: {
          d: "M114.52 15.3548C103.49 8.99344 87.6731 7.14226 73.8832 9.78936C72.4155 8.29102 70.7436 7.00746 68.9169 5.97671C57.4223 -0.658903 38.845 -0.658903 27.422 5.97671C17.7488 11.5988 16.3239 20.175 23.0787 26.7003C24.2764 27.8967 25.6312 28.9246 27.106 29.7558L90.3262 66.2457C99.9725 71.8142 115.56 71.8142 125.144 66.2457L125.478 66.043C125.594 65.9804 125.704 65.9178 125.82 65.8493C135.777 60.0632 135.726 50.6851 125.704 44.8991C124.282 44.0927 122.793 43.4095 121.254 42.8571C129.675 34.0931 127.424 22.8072 114.52 15.3548",
          stroke: "#006CD4",
          "stroke-width": "2",
          "stroke-miterlimit": "10",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }