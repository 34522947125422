
import { openAuthWindow } from '@/modules/auth'
import { CsFlex } from '@consteel/csuetify'
import Vue from 'vue'

export default Vue.extend({
  name: 'TopMenu',
  components: {
    CsFlex,
  },
  methods: {
    openAuthWindow: openAuthWindow,
  },
})
