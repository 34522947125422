
import { CsFlex } from '@consteel/csuetify'
import Vue from 'vue'
import LegalDialog from './LegalDialog.vue'

export default Vue.extend({
  name: 'FooterLinks',
  components: {
    LegalDialog,
    CsFlex,
  },
  data: () => ({
    showLegalDialog: false,
  }),
})
